import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import OTP from "../../Components/Authentication/OTP";
import Profile from "../User-panel/Profile";
import PrivacyPolicies from "./PrivacyPolicies";
import OTPVerify from "../../Components/Authentication/VerifyOTP";
import Login from "../../Components/Authentication/Login";
import AdminLogin from "../../Components/Authentication/AdminLogin";
import Home from "./Home";
import Chatbot from "./Chatbot";
import Gmail from "./Gmail";
import Instagram from "./Instagram";
import Mail from "./Mail";
import InstagramPosts from "./InstagramPosts";
import ChatAI from "./ChatAI";
import AllowInstaAccess from "../../Components/UserPanel/Instagram/AllowInstaAccess";
import TransactionHistory from "../../Components/UserPanel/Instagram/TransactionHistory";
import ChangePassword from "../../Components/Authentication/ChangePassword";
import ForgotPassword from "../../Components/Authentication/ForgotPassword";
import ResetPassword from "../../Components/Authentication/ResetPassword";
import UserLayout from "./UserLayout";
import TermsandConditions from "./TermsandConditions";
import Faq from "../User-panel/Faq";
import TikTokLogin from "./Tiktoklogin";
import AuthCallback from "../../Components/Authentication/AuthCallback";
import SocketContext from "../../Components/Authentication/SocketContext";
import TiktokRedirect from "../../Components/Authentication/Tiktokredirect";
import TiktokUserProfile from "./TiktokUserProfile";

function App() {
  return (
    <>
      <UserLayout>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/admin" element={<AdminLogin />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/otp" element={<OTP />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          {/* <Route path="/home" element={<Home />}></Route> */}
          <Route path="/verifyotp" element={<OTPVerify />}></Route>
          <Route path="/faq" element={<Faq />}></Route>
          <Route path="/instagramaccess" element={<AllowInstaAccess />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicies />}></Route>
          <Route path="/termsandconditions" element={<TermsandConditions />}></Route>
          <Route path="/Chatbot" element={<Chatbot />}></Route>
          <Route path="/gmail" element={<Gmail />}></Route>
          <Route path="/Instagram" element={<Instagram />}></Route>
          <Route path="/mail" element={<Mail />}></Route>
          <Route path="/transactionhistory" element={<TransactionHistory />}></Route>
          <Route path="/igposts" element={<InstagramPosts />}></Route>
          <Route path="/Chatai" element={<ChatAI />}></Route>
          <Route path="/changepassword" element={<ChangePassword />}></Route>
          <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
          <Route path="/resetpassword" element={<ResetPassword />}></Route>
          <Route path="/qrcode" element={<SocketContext />}></Route>
          <Route path="/tiktok" element={<TikTokLogin />}></Route>
          <Route path="/tiktokuserprofile" element={<TiktokUserProfile />}></Route>
          <Route path="/auth/callback" element={<AuthCallback />} />
          <Route path="/tiktokredirect" element={<TiktokRedirect />} />
        </Routes>
      </UserLayout>
    </>
  );
}

export default App;
