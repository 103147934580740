import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { RegisterUser } from "../../redux/auth/authSlice";
import GoogleAuthentication from "../../Components/GoogleAuthentication";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "../../App.css";
import Loader from "../Loader/Loader";
import ProjectWlogo from "../../../src/Utils/Images/ProjectWlogo.svg";
import Aidashboardimg from "../../../src/Utils/Images/dashboardimg.jpg";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state?.counter);
  const [error, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [registerData, setRegisterData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const HandleChange = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
    setErrors({ ...error, [e.target.name]: undefined });
  };

  const Validation = () => {
    const errors = {};

    if (registerData?.username === "" && registerData?.username?.length === 0) {
      errors.username = "This field is required";
    }

    if (registerData?.email === "" && registerData?.email?.length === 0) {
      errors.email = "This field is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(registerData?.email)) {
      errors.email = "Invalid email address";
    }

    if (registerData?.password === "" && registerData?.password?.length === 0) {
      errors.password = "This field is required";
    } else if (registerData?.password?.length < 6) {
      errors.password = "Password must be at least 6 characters long.";
    } else if (/^\d+$/.test(registerData?.password)) {
      errors.password = "Password cannot contain numbers only.";
    } else if (!/[A-Z]/.test(registerData?.password)) {
      errors.password = "Password must contain at least one uppercase letter.";
    } else if (!/[0-9!@#$%^&*(),.?":{}|<>]/.test(registerData?.password)) {
      errors.password = "Password must contain at least one special character or number.";
    }

    setErrors(errors);
    const hasErrors = Object.keys(errors).length > 0;
    return hasErrors;
  };

  const HandleSignUp = (e) => {
    e?.preventDefault();
    let data = Validation();
    if (data) {
      return;
    } else {
      dispatch(RegisterUser({ registerData, navigate, dispatch }));
    }
  };

  const TogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {isLoading && <Loader />}
      <header className="header">
        <div className="logo-container">
          <img src={ProjectWlogo} alt="Logo" className="logo" />
        </div>
      </header>
      <section className="background-radial-gradient overflow-hidden">
        <div className="register_css">
          <div className="container px-4 py-3 px-md-5  text-lg-start my-5">
            <div className="row gx-lg-5 align-items-center mb-5">
              <div className="col-lg-6 mb-5 mb-lg-0" style={{ zIndex: "10", boxShadow: "10px 10px 5px white" }}>
                <img src={Aidashboardimg} height={320} width={500} style={{ borderRadius: "10px" }} className="resp_login_img" />
                <h1 className="my-5 display-5 fw-bold ls-tight" style={{ color: "#4b49ac" }}>
                  Future of <br />
                  <span style={{ color: "#4b49ac" }}>Artificial Intelligence</span>
                </h1>
                <p className="mb-4 opacity-70" style={{ color: "3e3e3e" }}>
                  Artificial intelligence refers to computer systems that can perform tasks commonly associated with human cognitive functions — such as interpreting speech,
                  playing games and identifying patterns.
                </p>
              </div>

              <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
                <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
                <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

                <div className="card bg-glass bg_register_css">
                  <div className="card-body px-4 py-5 px-md-5">
                    <div>
                      <div className="form-outline mb-2 Label_css">
                        <label className="form-label" htmlFor="form3Example3">
                          <h4>
                            <b style={{ color: "#4b49ac" }}>Register</b>
                          </h4>
                        </label>
                      </div>

                      <div className="form-outline mb-2">
                        <label className="form-label" htmlFor="form3Example3">
                          Full Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input style={{ border: "none" }} type="text" name="username" className="form-control input_css_for_all_placeholder" onChange={(e) => HandleChange(e)} />
                        <p style={{ color: "red" }}>{error.username}</p>
                      </div>

                      <div className="form-outline mb-2">
                        <label className="form-label" htmlFor="form3Example3">
                          Email address <span style={{ color: "red" }}>*</span>
                        </label>
                        <input style={{ border: "none" }} type="email" name="email" className="form-control input_css_for_all_placeholder" onChange={(e) => HandleChange(e)} />
                        <p style={{ color: "red" }}>{error.email}</p>
                      </div>

                      <div className="form-outline mb-2  position-relative">
                        <label className="form-label" htmlFor="form3Example4">
                          Password <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          style={{ border: "none" }}
                          type={showPassword ? "text" : "password"}
                          name="password"
                          className="form-control input_css_for_all_placeholder"
                          onChange={(e) => HandleChange(e)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              HandleSignUp(e);
                            }
                          }}
                        />
                        <div className="pass-toggle-reg">
                          <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} className="password-toggle-icon" onClick={TogglePasswordVisibility} />
                        </div>
                        <p style={{ color: "red" }}>{error.password}</p>
                      </div>

                      <button type="submit" className="btn btn-primary btn-block mb-4 btn-100" onClick={(e) => HandleSignUp(e)} style={{ background: "#f3797e", border: "none" }}>
                        Sign up
                      </button>

                      <div className="text-center">
                        <p>
                          Already Registered?{" "}
                          <Link to="/" style={{ textDecoration: "none", color: "#4b49ac" }}>
                            Sign in
                          </Link>
                        </p>
                        <p>or sign up with</p>
                        <button type="button" className="btn btn-link btn-floating mx-1">
                          <GoogleAuthentication />
                        </button>
                        <div className="my-3">
                          <Link to="/qrcode" style={{ textDecoration: "none" }}>
                            <p style={{ fontSize: "14px" }}>Login with QR Code</p>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Register;
