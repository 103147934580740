import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { ChangePassWord } from "../../redux/auth/authSlice";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../Common/Common";

const ChangePassword = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onFinish = (values) => {
    let obj = {
      email: GetDecryptEmailformat(),
      reference_number: GetDecryptReferenceNumberformat(),
      old_password: values?.currentPassword,
      new_password: values?.newPassword,
      confirm_new_password: values?.confirmPassword,
    };
    dispatch(ChangePassWord({ obj, dispatch })).then((response) => {
      if (response?.success) {
        form.resetFields();
      }
    });
  };

  const validatePassword = (_, value) => {
    const containsUppercase = /[A-Z]/.test(value);
    const notOnlyNumbers = /\D/.test(value);

    if (!value) {
      return Promise.reject(new Error("Please input your new password!"));
    }

    if (!containsUppercase) {
      return Promise.reject(new Error("Password must contain at least one uppercase letter."));
    }

    if (!notOnlyNumbers) {
      return Promise.reject(new Error("Password cannot contain numbers only."));
    }

    if (value.length < 6) {
      return Promise.reject(new Error("Password must be at least 6 characters long."));
    }

    return Promise.resolve();
  };

  return (
    <div className="billing-container">
      <div className="aws-tab-content" style={{ maxWidth: "400px", margin: "auto", padding: "20px" }}>
        <h2 style={{ font: "24px", fontWeight: "bold", textAlign: "center" }}>Change Password</h2>
        <Form form={form} name="change_password" layout="vertical" onFinish={onFinish} autoComplete="off">
          <Form.Item
            name="currentPassword"
            label={
              <span>
                Current Password <span style={{ color: "red" }}>*</span>
              </span>
            }
            required={false}
            rules={[{ required: true, message: "Please input your current password!" }]}
          >
            <Input.Password
              placeholder="Enter current password"
              type={showCurrentPassword ? "text" : "password"}
              iconRender={(visible) =>
                visible ? (
                  <EyeInvisibleOutlined onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                ) : (
                  <EyeOutlined onClick={() => setShowCurrentPassword(!showCurrentPassword)} />
                )
              }
            />
          </Form.Item>

          <Form.Item
            name="newPassword"
            label={
              <span>
                New Password <span style={{ color: "red" }}>*</span>
              </span>
            }
            required={false}
            rules={[{ validator: validatePassword, required: true }]}
          >
            <Input.Password
              placeholder="Enter new password"
              type={showNewPassword ? "text" : "password"}
              iconRender={(visible) =>
                visible ? <EyeInvisibleOutlined onClick={() => setShowNewPassword(!showNewPassword)} /> : <EyeOutlined onClick={() => setShowNewPassword(!showNewPassword)} />
              }
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label={
              <span>
                Confirm Password <span style={{ color: "red" }}>*</span>
              </span>
            }
            required={false}
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The two passwords do not match!"));
                },
              }),
            ]}
          >
            <Input.Password
              placeholder="Confirm new password"
              type={showConfirmPassword ? "text" : "password"}
              iconRender={(visible) =>
                visible ? (
                  <EyeInvisibleOutlined onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                ) : (
                  <EyeOutlined onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                )
              }
            />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block>
              Change Password
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ChangePassword;
