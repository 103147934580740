import React, { useEffect, useState } from "react";
import { Row, Col, Button, Tabs, Typography, Divider, Modal, Form, Input, Upload, message } from "antd";
import { EditOutlined, SettingOutlined, UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";
import { GetTiktokProfileData, PosttiktokMedia } from "../../redux/auth/authSlice";
import Loader from "../../Components/Loader/Loader";
import userpic from "../../Utils/Images/userpic.png";

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const TikTokPage = () => {
  const [form] = Form.useForm();
  const [isEditModalVisible, setEditModalVisible] = useState(false);
  const [isSettingsModalVisible, setSettingsModalVisible] = useState(false);
  const [isPostModalVisible, setPostModalVisible] = useState(false);

  const [postDetails, setPostDetails] = useState({
    title: "",
    description: "",
    image: null,
  });

  const handleEditModalOpen = () => setEditModalVisible(true);
  const handleEditModalClose = () => setEditModalVisible(false);

  const handleSettingsModalOpen = () => setSettingsModalVisible(true);
  const handleSettingsModalClose = () => setSettingsModalVisible(false);

  const handlePostModalOpen = () => setPostModalVisible(true);
  const handlePostModalClose = () => setPostModalVisible(false);

  const handlePostSubmit = () => {
    form
      .validateFields()
      .then(() => {
        const image = form.getFieldValue("image");

        if (!image || image.length === 0) {
          return;
        }

        const file = image[0]?.originFileObj;

        if (!postDetails.title || !postDetails.description || !file) {
          message.error("Please fill all fields and upload an image.");
          return;
        }

        let obj = {
          email: GetDecryptEmailformat(),
          reference_number: GetDecryptReferenceNumberformat(),
          image: file,
          title: postDetails?.title,
          description: postDetails?.description,
        };

        dispatch(PosttiktokMedia({ obj, dispatch }));

        form.resetFields();
        setPostDetails({ title: "", description: "", image: null });
        setPostModalVisible(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleFieldChange = (field, value) => {
    setPostDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  const { gettiktokuserdetails, isLoading } = useSelector((state) => state?.counter);

  const [tiktokdetails, setTiktokDetails] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    const userData = {
      email: GetDecryptEmailformat(),
      reference_number: GetDecryptReferenceNumberformat(),
    };
    dispatch(GetTiktokProfileData({ userData, dispatch })).then((response) => {});
  }, [dispatch]);

  useEffect(() => {
    if (gettiktokuserdetails) {
      setTiktokDetails(gettiktokuserdetails);
    }
  }, [gettiktokuserdetails]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
      {/* Profile Header */}
      <Row align="middle" gutter={[16, 16]} style={{ marginBottom: "20px" }}>
        <Col>
          <img
            src={tiktokdetails[0]?.avatar_url ? tiktokdetails[0]?.avatar_url : userpic}
            alt="Avatar"
            style={{
              width: 80,
              height: 80,
              backgroundColor: "#f56a00",
              fontSize: "32px",
              borderRadius: "50%",
            }}
          />
        </Col>
        <Col>
          <Title level={4}>{tiktokdetails[0]?.display_name}</Title>
          <Text>{tiktokdetails[0]?.bio_description}</Text>
          <div>
            <Text>0 Following · 0 Followers · 0 Likes</Text>
          </div>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Button icon={<EditOutlined />} onClick={handleEditModalOpen}>
            Edit Profile
          </Button>
        </Col>
        <Col>
          <Button icon={<SettingOutlined />} onClick={handleSettingsModalOpen}>
            Settings
          </Button>
        </Col>
        <Col>
          <Button icon={<PlusOutlined />} type="primary" onClick={handlePostModalOpen}>
            Post
          </Button>
        </Col>
      </Row>

      <Divider />

      <Tabs defaultActiveKey="1">
        <TabPane tab="Videos" key="1">
          <div style={{ textAlign: "center", padding: "50px 0" }}>
            <Text>Upload your first video</Text>
          </div>
        </TabPane>
        <TabPane tab="Favorites" key="2">
          <div style={{ textAlign: "center", padding: "50px 0" }}>
            <Text>No favorites yet</Text>
          </div>
        </TabPane>
        <TabPane tab="Liked" key="3">
          <div style={{ textAlign: "center", padding: "50px 0" }}>
            <Text>No liked videos yet</Text>
          </div>
        </TabPane>
      </Tabs>

      <Modal title="Edit Profile" visible={isEditModalVisible} onOk={handleEditModalClose} onCancel={handleEditModalClose}>
        <Form layout="vertical">
          <Form.Item label="Username">
            <Input defaultValue="areatest14" />
          </Form.Item>
          <Form.Item label="Bio">
            <Input.TextArea defaultValue="this is my tiktok account" rows={3} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="Settings" visible={isSettingsModalVisible} onOk={handleSettingsModalClose} onCancel={handleSettingsModalClose}>
        <Form layout="vertical">
          <Form.Item label="Change Password">
            <Input.Password placeholder="Enter new password" />
          </Form.Item>
          <Form.Item label="Email Notifications">
            <Input type="email" placeholder="Enter your email for notifications" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal title="Create Post" visible={isPostModalVisible} onOk={handlePostSubmit} onCancel={handlePostModalClose}>
        <Form form={form} layout="vertical" onFinish={handlePostSubmit}>
          {" "}
          <Form.Item
            label="Title"
            name="title"
            rules={[
              { required: true, message: "Please enter a title." },
              { max: 50, message: "Title cannot exceed 50 characters." },
            ]}
            validateTrigger="onChange"
          >
            <Input value={postDetails.title} onChange={(e) => handleFieldChange("title", e.target.value)} placeholder="Enter post title" />
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            rules={[
              { required: true, message: "Please enter a description." },
              { max: 200, message: "Description cannot exceed 200 characters." },
            ]}
            validateTrigger="onChange"
          >
            <Input.TextArea value={postDetails.description} onChange={(e) => handleFieldChange("description", e.target.value)} placeholder="Enter post description" rows={3} />
          </Form.Item>
          <Form.Item
            label="Upload file"
            name="image"
            valuePropName="fileList"
            rules={[
              {
                required: true,
                message: "Please upload a file.",
              },
              {
                validator: (_, value) => {
                  if (!value || value.length === 0) {
                    return Promise.reject(new Error());
                  }
                  const isValidFileType = value[0]?.type === "image/jpeg" || value[0]?.type === "image/png";
                  if (!isValidFileType) {
                    return Promise.reject(new Error("Only JPEG/PNG files are allowed."));
                  }
                  const isValidFileSize = value[0]?.size / 1024 / 1024 < 2;
                  if (!isValidFileSize) {
                    return Promise.reject(new Error("File size must be less than 2MB."));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            getValueFromEvent={(e) => {
              if (e && e.fileList) {
                return e.fileList;
              }
              return [];
            }}
          >
            <Upload name="image" listType="picture" maxCount={1} beforeUpload={() => false}>
              <Button icon={<UploadOutlined />}>Upload File</Button>
            </Upload>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default TikTokPage;
