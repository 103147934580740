import React, { useState } from "react";
import { BiSolidPhoneCall } from "react-icons/bi";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AddPhoneNumber, VerifiedPhone } from "../../redux/auth/authSlice";
import "../../App.css";
import Loader from "../Loader/Loader";
import { GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../Common/Common";
import { Button } from "antd";

const Otp = () => {
  const { isLoading } = useSelector((state) => state?.counter);
  const [ph, setPh] = useState("");

  const [loading, setLoading] = useState(false);
  const [phoneError, setPhoneError] = useState("");

  const email = GetDecryptEmailformat();
  const referenceNumber = GetDecryptReferenceNumberformat();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  function OnSignup(event) {
    event?.preventDefault();
    setPhoneError("");

    if (!ph) {
      setPhoneError("Phone number is required");
      return;
    }

    setLoading(true);

    const callback = (response) => {
      let obj = {
        phone: "+" + ph,
        email: email,
        reference_number: referenceNumber,
      };
      dispatch(VerifiedPhone({ obj, navigate, dispatch }));
    };

    let obj = {
      email: email,
      phone: "+" + ph,
      reference_number: referenceNumber,
    };
    dispatch(AddPhoneNumber({ obj, dispatch, navigate, callback }));
  }

  const HandleNav = () => {
    navigate("/");
    localStorage.clear();
  };

  return (
    <>
      {isLoading && <Loader />}
      <section className="background-radial-gradient overflow-hidden min-100">
        <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
        <div
          className="signuppage mt-5 bg-light text-black text-v p-5 resp_otp_css otp_custom_css"
          style={{
            borderRadius: "15px",
            zIndex: "99999",
            position: "relative",
          }}
        >
          <div>
            <span className="d-flex justify-content-center">
              <BiSolidPhoneCall size={40} />
            </span>
            <h6 className="text-center mt-3">Please Add your phone number</h6>
            <div className="resp_otp_css_form">
              <PhoneInput
                country={"in"}
                value={ph}
                onChange={setPh}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    OnSignup();
                  }
                }}
              />
            </div>
            {phoneError && (
              <div
                style={{
                  color: "red",
                  textAlign: "center",
                  marginTop: "10px",
                }}
              >
                {phoneError}
              </div>
            )}
            <div className="d-flex justify-content-center">
              <button className="btn btn-primary mt-3 w-75" onClick={OnSignup} disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm" style={{ marginRight: "10px" }}></span>}
                <span>Submit</span>
              </button>
            </div>
            <br />
            <div id="recaptcha-container" className="mt-6"></div>
          </div>
        </div>
        <Button
          type="text"
          icon={<ArrowLeftOutlined />}
          onClick={() => HandleNav()}
          style={{
            fontSize: "15px",
            position: "absolute",
            top: "16px",
            left: "16px",
            zIndex: "99999",
          }}
        >
          Go Back
        </Button>
        <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>
        <div className="row position-absolute text-white p-5"></div>
      </section>
    </>
  );
};

export default Otp;
