import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllowInstagramAccess, GetInstagramDetails, GetInstagramUserDetailsInfo } from "../../redux/auth/authSlice";

import {
  GetDecryptEmailformat,
  GetDecryptInstagramEID,
  GetDecryptInstagramUID,
  GetDecryptPasswordformat,
  GetDecryptReferenceNumberformat,
  GetDecryptUserNameformat,
} from "../../Components/Common/Common";

import InstagramPage from "../../Components/UserPanel/Instagram/InstagramPage";

const Instagram = () => {

  const { walletBalanceUser, getUserWalletDetails, TokenID, ImageCaptionGenerate } = useSelector((state) => state?.counter);

  const [instaonchangedataModal, setInstaonchangedataModal] = useState({
    username: "",
    password: "",
  });

  const decryptusername = GetDecryptUserNameformat();

  const decryptpassword = GetDecryptPasswordformat();

  const email = GetDecryptEmailformat();

  const referenceNumber = GetDecryptReferenceNumberformat();

  const dispatch = useDispatch();
  const [instaonchangedata, setInstaonchangedata] = useState({
    username: decryptusername ? decryptusername : "",
    password: decryptpassword ? decryptpassword : "",
    caption: "",
  });


  useEffect(() => {
    setInstaonchangedata({ ...instaonchangedata, caption: ImageCaptionGenerate });
  }, [ImageCaptionGenerate]);

  // let obj = {
  //   email: email,
  //   reference_number: referenceNumber,
  //   username: instaonchangedata?.username ? instaonchangedata?.username : decryptusername,
  //   password: instaonchangedata?.password ? instaonchangedata?.password : decryptpassword,
  // };

  function getCookie(name) {
    const cookieName = name + "=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(";");

    for (let i = 0; i < cookiesArray.length; i++) {
      let cookie = cookiesArray[i].trim();
      if (cookie.indexOf(cookieName) === 0) {
        return cookie.substring(cookieName.length, cookie.length);
      }
    }
    return null;
  }

  useEffect(() => {
    const rememberMe = getCookie("InsRM");
    if (rememberMe) {
      const email1 = getCookie("InsEID");
      const password2 = getCookie("InsPID");

      let username = GetDecryptInstagramEID(email1);

      let password = GetDecryptInstagramUID(password2);

      setInstaonchangedataModal((prevState) => ({ ...prevState, username, password }));
    }
  }, []);

  useEffect(() => {
    if (getUserWalletDetails) {
      dispatch(GetInstagramUserDetailsInfo({ dispatch, TokenID, getUserWalletDetails, walletBalanceUser, email, referenceNumber }));
    }
  }, [TokenID, getUserWalletDetails]);

  useEffect(() => {
    if (getUserWalletDetails) {
      dispatch(GetInstagramDetails({ email, referenceNumber, dispatch }));
    }
  }, [getUserWalletDetails]);

  useEffect(() => {
    setTimeout(() => {
      if (email && referenceNumber) {
        dispatch(AllowInstagramAccess({ email, referenceNumber, dispatch }));
      }
    }, 500);
  
  }, [dispatch]);

  return <InstagramPage />;
};

export default Instagram;
