import React, { useEffect, useState, useMemo } from "react";
import { Drawer, Tooltip, Input, Button, Dropdown, Space, Menu, Modal } from "antd";
import { FaHistory } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import "../../style/admin.css";
import logo from "../../Utils/Images/ProjectWlogo.svg";
import { DeleteUserChatHistory, GetUserChatHistory } from "../../redux/auth/authSlice";
import { GetDecryptEmailformat } from "../../Components/Common/Common";

const UserHistory = ({ answerRefs, userchatHistory, setSelectedItem, selectedItem }) => {
  const { userHistory } = useSelector((state) => state?.counter);
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [userChat, setUserChat] = useState([]);
  const showDrawer = () => setOpen(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);

  const dispatch = useDispatch();
  const onClose = () => setOpen(false);

  const items = [
    {
      key: "1",
      label: <p>Text</p>,
      onClick: () => {
        setSelectedItem("text");
        const email = GetDecryptEmailformat();
        const type = "text";
        dispatch(GetUserChatHistory({ dispatch, email, type, setUserChat }));
      },
    },
    {
      key: "2",
      label: <p>Image</p>,
      onClick: () => {
        setSelectedItem("image");
        const email = GetDecryptEmailformat();
        const type = "image";
        dispatch(GetUserChatHistory({ dispatch, email, type, setUserChat }));
      },
    },
    {
      key: "3",
      label: <p>Video</p>,
      onClick: () => {
        setSelectedItem("video");
        const email = GetDecryptEmailformat();
        const type = "video";
        dispatch(GetUserChatHistory({ dispatch, email, type, setUserChat }));
      },
    },
    {
      key: "4",
      label: <p>Audio</p>,
      onClick: () => {
        setSelectedItem("voice");
        const email = GetDecryptEmailformat();
        const type = "voice";
        dispatch(GetUserChatHistory({ dispatch, email, type, setUserChat }));
      },
    },
  ];

  useEffect(() => {
    if (userchatHistory || userHistory) {
      setUserChat(userHistory);
    }
  }, [userHistory]);

  useEffect(() => {
    if (text) {
      const SearchfilteredData = userHistory?.filter(
        (ele) =>
          ele?.user_input?.text?.toLowerCase().includes(text.toLowerCase()) ||
          ele?.prompt?.toLowerCase().includes(text.toLowerCase()) ||
          ele?.response?.toLowerCase().includes(text.toLowerCase())
      );
      setUserChat(SearchfilteredData);
    } else {
      setUserChat(userHistory);
    }
  }, [text, userHistory]);

  const filterUserHistory = useMemo(() => {
    return userChat?.map((ele) => ({
      ...ele,
      UserchatHeader: `Q: ${ele?.response ? ele?.response?.slice(0, 10) : ele?.user_input?.text ? ele?.user_input?.text?.slice(0, 10) : ele?.prompt?.slice(0, 10) || "N/A"} | A: ${
        ele?.response_text?.slice(0, 20) || ele?.response?.slice(0, 20) || ele?.video_url?.slice(0, 20) || ele?.prompt?.slice(0, 20)
      }...`,
    }));
  }, [userChat]);

  const handleClick = (index) => {
    const targetElement = answerRefs.current[index];
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth", block: "center" });
      setOpen(false);
    }
  };

  const HandleDelete = (id, data) => {
    const email = GetDecryptEmailformat();
    if (data?.response_text) {
      const type = "text";
      dispatch(DeleteUserChatHistory({ dispatch, id, email, type, setUserChat }));
    } else if (data?.video_url && data?.prompt) {
      const type = "video";
      dispatch(DeleteUserChatHistory({ dispatch, id, email, type, setUserChat }));
    } else if (data?.prompt) {
      const type = "image";
      dispatch(DeleteUserChatHistory({ dispatch, id, email, type, setUserChat }));
    } else if (data?.audio_base64) {
      const type = "voice";
      dispatch(DeleteUserChatHistory({ dispatch, id, email, type, setUserChat }));
    }
  };

  const indianDate = (date) => {
    const indianDate = new Date(date).toLocaleString("en-IN", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return indianDate;
  };

  const HandleChangeText = (e) => {
    setText(e.target.value);
  };

  const handleDeleteClick = (chat) => {
    setSelectedChat(chat);
    setIsModalOpen(true);
  };

  const handleConfirmDelete = () => {
    if (selectedChat) {
      HandleDelete(selectedChat.id, selectedChat);
    }
    setIsModalOpen(false);
    setSelectedChat(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedChat(null);
  };

  return (
    <>
      <Tooltip placement="bottom" title="History">
        <FaHistory className="history_user_css" onClick={showDrawer} />
      </Tooltip>

      <Drawer title="Chat History" onClose={onClose} open={open} placement="left">
        <div>
          <b>Chat History:</b>

          <div className="user_history_dropdown_css my-2">
            <div>
              <Input placeholder="Search History" onChange={(e) => HandleChangeText(e)} />
            </div>

            <div>
              <Space direction="vertical">
                <Space wrap>
                  <Dropdown
                    overlay={
                      <Menu>
                        {items.map((item) => (
                          <Menu.Item key={item.key} onClick={item.onClick}>
                            {item.label}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                    placement="bottom"
                    arrow
                    trigger={["click"]}
                    autoAdjustOverflow={true}
                  >
                    <Button>{selectedItem}</Button>
                  </Dropdown>
                </Space>
              </Space>
            </div>
          </div>

          {filterUserHistory?.length > 0
            ? filterUserHistory?.map((ele, index) => (
                <div key={index} className="UserChat_css my-3" onClick={() => handleClick(index)}>
                  <div className="chat_img_css">
                    <img src={logo} />
                  </div>
                  <div>
                    <b className="userchat_text_css">{ele?.UserchatHeader}</b>
                    <div className="history_css">
                      <p>{indianDate(ele?.created_at)}</p>

                      <Tooltip placement="bottom" title="Delete Chat" key={ele.id}>
                        <i onClick={() => handleDeleteClick(ele)} className={`fa-solid fa-trash-can delete_chatai_icon_css mx-2`}></i>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ))
            : "No History found"}
        </div>
      </Drawer>

      <Modal title="Delete Chat" visible={isModalOpen} onOk={handleConfirmDelete} onCancel={handleCancel} okText="Delete" cancelText="Cancel">
        <p>Are you sure you want to delete this chat?</p>
      </Modal>
    </>
  );
};

export default UserHistory;
