import "./App.css";
import UserRoutes from "./Pages/User-panel/Route";
import AdminRoutes from "./Pages/Admin-panel/Route";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./Components/Authentication/Register";
import { useSelector } from "react-redux";

function App() {
  const { userRole } = useSelector((auth) => auth.counter);

  if (userRole === "USER") {
    return (
      <>
        <Router>
          <Routes>
            <Route path="/*" element={<UserRoutes />}></Route>
            <Route path="/signup" element={<Register />}></Route>
          </Routes>
        </Router>
      </>
    );
  }
  else if (userRole !== "USER") {

  return (
    <>
      <Router>
        <Routes>
          <Route path="/*" element={<AdminRoutes />}></Route>
          <Route path="/signup" element={<Register />}></Route>
        </Routes>
      </Router>
    </>
  );
}
}

export default App;
