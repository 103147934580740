import React, { useEffect } from "react";
// import { useNavigate } from "react-router";
import Loader from "../../../Components/Loader/Loader";
// import { FaBars, FaCaretLeft } from "react-icons/fa";
// import { Dropdown, Space } from "antd";
// import avatar from "../../../Utils/Images/userpic.png";
// import { Link } from "react-router-dom";
// import { UserOutlined, LogoutOutlined } from "@ant-design/icons";

import "./navbar.css";
import { DecryptedDataAdminlocalStorage, EncryptDataAdmin, GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../../Components/Common/Common";
import { AdminLogout, GetProfile, GetProfileAdmin } from "../../../redux/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
const Navbar = ({ HandleToggle }) => {
  // const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading, profileDetailsAdmin } = useSelector((state) => state?.counter);

  useEffect(() => {
    let obj = {
      user_id: GetDecryptEmailformat(),
      email: GetDecryptEmailformat(),
      reference_number: GetDecryptReferenceNumberformat(),
    };

    if (obj?.user_id) {
      dispatch(GetProfile({ obj, dispatch }));
    }
  }, [dispatch]);

  // const items = [
  //   {
  //     label: (
  //       <div className="Navbar_css">
  //         <UserOutlined style={{ marginRight: 8 }} />
  //         <Link to="/profile">Profile</Link>
  //       </div>
  //     ),
  //     key: "1",
  //   },
  //   {
  //     label: (
  //       <div className="Navbar_css">
  //         <LogoutOutlined style={{ marginRight: 8 }} />
  //         Logout
  //       </div>
  //     ),
  //     key: "2",
  //     onClick: () => {
  //       const referenceNumber = localStorage?.getItem("reference_number");

  //       let obj = {
  //         reference_number: DecryptedDataAdminlocalStorage(referenceNumber),
  //       };
  //       let encBody = EncryptDataAdmin(JSON.stringify(obj));

  //       let objData = {
  //         enc: encBody,
  //       };

  //       dispatch(AdminLogout({ objData, dispatch, navigate }));
  //     },
  //   },
  // ];

  // const referenceNumber = localStorage?.getItem("reference_number");

  // useEffect(() => {
  //   let obj = {
  //     reference_number: DecryptedDataAdminlocalStorage(referenceNumber),
  //   };
  //   let encBody = EncryptDataAdmin(JSON.stringify(obj));

  //   let objData = {
  //     enc: encBody,
  //   };

  //   dispatch(GetProfileAdmin({ objData, dispatch }));
  // }, [dispatch]);

  // const profilePictureUrl = profileDetailsAdmin?.profile_picture_url;

  // const username = localStorage.getItem("username");

  // const decryptUsername = DecryptedDataAdminlocalStorage(username);

  if (isLoading) {
    return <Loader />;
  }
  
  return (
    <>
      {/* <section className="dasboard-all top-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-flex">
                <ul>
                  <li>
                    <button onClick={HandleToggle} className="tog-bt">
                      <FaBars className="fa-solid fa-bars" />
                      <FaCaretLeft className="fa-solid fa-caret-left" />
                    </button>
                  </li>

                  <li className="d-flex align-items-center flex-wrap " style={{ gap: "13px" }}>
                    <div className="profile-right" style={{ cursor: "pointer" }}>
                      <Dropdown
                        menu={{
                          items,
                        }}
                        trigger={["click"]}
                      >
                        <Space>
                          <div className="profile-card">
                            <div className="profile-card-img">
                              <img className="rounded-circle" src={profilePictureUrl ? profilePictureUrl : avatar} height={50} width={50} alt="Profile" />
                            </div>
                            <p style={{ fontWeight: "600" }}>{decryptUsername ? decryptUsername : "Admin"}</p>
                          </div>
                        </Space>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default Navbar;
