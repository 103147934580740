import { Badge, Button, Card, Space, Switch, Tabs, Upload } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  AddPost,
  AllowInstagramAccess,
  GeneratedCaptionApi,
  GetInstagramUserDetailsInfo,
  GetTokenId,
  HandleS3BucketImage,
  InstagramMessageApi,
  UploadPostInstagram,
} from "../../../redux/auth/authSlice";
import { GetDecryptEmailformat, GetDecryptPasswordformat, GetDecryptReferenceNumberformat, GetDecryptUserNameformat } from "../../Common/Common";
import TransactionHistory from "./TransactionHistory";
import { Flex, Tooltip, Drawer } from "antd";
import AllowInstaAccess from "./AllowInstaAccess";
import SetupCredentialsForm from "./SetupCredentialsForm";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { UploadOutlined } from "@ant-design/icons";
const { Meta } = Card;

export default function InstagramPage() {
  // const imageInputRef = useRef(null);
  const [error, setError] = useState({});
  const [fileupload, setFileupload] = useState("");
  const referenceNumber = GetDecryptReferenceNumberformat();

  const email = GetDecryptEmailformat();
  const decryptusername = GetDecryptUserNameformat();
  const decryptpassword = GetDecryptPasswordformat();

  const [details, setDetails] = useState({
    username: decryptusername ? decryptusername : "",
    password: decryptpassword ? decryptpassword : "",
    caption: "",
  });
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCaptionDisabled, setIsCaptionDisabled] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  const query = new URLSearchParams(location.search);
  const shouldPost = query.get("shouldPost");

  const defaultTab = shouldPost ? "4" : "1";
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);

    if (query.has("shouldPost")) {
      query.delete("shouldPost");

      const newUrl = `${location.pathname}?${query.toString()}`;

      navigate(newUrl, { replace: true });
    }
  }, [location.search, navigate]);
  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    const savedTab = localStorage.getItem("activeInstagramTab");
    if (savedTab) {
      setActiveTab(savedTab);
    }
  }, []);

  const handleTabChange = (key) => {
    setActiveTab(key);
    localStorage.setItem("activeInstagramTab", key);
  };

  const { InstagramProfileData, ImageCaptionGenerate, InstagramUserDetails, S3BucketImage, getUserWalletDetails, walletBalanceUser, tokenExist, TokenID } = useSelector(
    (state) => state?.counter
  );
  const base64Image = (InstagramProfileData?.profilePicUrl && `data:image/png;base64,${InstagramProfileData?.profilePicUrl}`) || null;

  useEffect(() => {
    setDetails({ ...details, caption: ImageCaptionGenerate });
   
    setError((prevError) => ({ ...prevError, caption: undefined }));
    setIsCaptionDisabled(false);
  }, [ImageCaptionGenerate]);

  // const HandleFileChange = (e) => {
  //   const file = e.target.files[0];

  //   if (file) {
  //     if (file.size > 2 * 1024 * 1024) {
  //       setError({ ...error, imgsize: "File format must be JPG." });
  //       toast.error("File size must be less than 2MB.");
  //       setFileupload(null);
  //       return;
  //     }

  //     if (!file.type.includes("image/jpeg")) {
  //       setError({ ...error, imgerr: "File format must be JPG." });
  //       toast.error("File format must be JPG.");
  //       setFileupload(null);
  //       return;
  //     }

  //     if (file && file?.name) {
  //       setFileupload(file);
  //       let obj = {
  //         file: file,
  //         file_name: file?.name,
  //         file_type: file?.type,
  //       };
  //       dispatch(
  //         GeneratedCaptionApi({
  //           email,
  //           referenceNumber,
  //           image: file,
  //           dispatch,
  //           obj,
  //         })
  //       );
  //     }

  //     setError({ ...fileupload, [e.target.id]: undefined });
  //   }
  // };

  // const HandleFileChange = (fileList) => {
  //   if (!fileList || !fileList.length || !fileList[0]?.originFileObj) {
  //     console.error("Invalid file list or file object");
  //     return;
  //   }

  //   const fileObject = fileList[0].originFileObj;
  //   setFileupload(fileObject);
  //   if (fileObject.size > 2 * 1024 * 1024) {
  //     setError({ ...error, imgsize: "File size must be less than 2MB." });
  //     toast.error("File size must be less than 2MB.");
  //     dispatch(HandleS3BucketImage(""));
  //     setFileList([]);
  //     return;
  //   }

  //   if (!fileObject.type.includes("image/jpeg")) {
  //     setError({ ...error, imgerr: "File format must be JPG." });
  //     toast.error("File format must be JPG.");
  //     dispatch(HandleS3BucketImage(""));
  //     setFileList([]);
  //     return;
  //   }

  //   const obj = {
  //     file: fileObject,
  //     file_name: fileObject.name,
  //     file_type: fileObject.type,
  //   };

  //   if (obj.file && obj.file_name && obj.file_type) {
  //     dispatch(
  //       GeneratedCaptionApi({
  //         email,
  //         referenceNumber,
  //         image: fileObject,
  //         dispatch,
  //         obj,
  //       })
  //     ).then(() => {
  //       setError((prevError) => ({ ...prevError, image: undefined, imgsize: undefined, imgerr: undefined }));
  //     });
  //   } else {
  //     console.error("File details are missing");
  //   }

  //   setError((prevError) => ({ ...prevError, imgsize: undefined, imgerr: undefined }));
  // };
  const validateAspectRatio = (width, height) => {
    const aspectRatio = width / height;

    const isSquare = Math.abs(aspectRatio - 1) < 0.01;
    const isPortrait = Math.abs(aspectRatio - 0.8) < 0.01;
    const isLandscape = Math.abs(aspectRatio - 1.91) < 0.01;

    return isSquare || isPortrait || isLandscape;
  };

  const HandleFileChange = (fileList) => {

    if (!fileList || !fileList.length || !fileList[0]?.originFileObj) {
      console.error("Invalid file list or file object");
      return;
    }

    const fileObject = fileList[0].originFileObj;
    setFileupload(fileObject);
    setError((prevError) => ({
      ...prevError,
      imgsize: undefined,
      imgerr: undefined,
      aspectRatio: undefined,
    }));

    if (fileObject.size > 2  *1024 * 1024) {
      setError({ ...error, imgsize: "File size must be less than 2MB." });
      toast.error("File size must be less than 2MB.");
      dispatch(HandleS3BucketImage(""));
      setFileList([]);
      return;
    }

    if (!fileObject.type.includes("image/jpeg")) {
      setError({ ...error, imgerr: "File format must be JPG." });
      toast.error("File format must be JPG.");
      dispatch(HandleS3BucketImage(""));
      setFileList([]);
      return;
    }

    const img = new Image();
    img.src = URL.createObjectURL(fileObject);

    img.onload = () => {
      const width = img.width;
      const height = img.height;

      if (!validateAspectRatio(width, height)) {
        setError((prevError) => ({
          ...prevError,
          aspectRatio: "Invalid image dimensions. The aspect ratio must be 1:1, 4:5, or 1.91:1.",
        }));
        toast.error("Invalid image dimensions. The aspect ratio must be 1:1, 4:5, or 1.91:1.");
        dispatch(HandleS3BucketImage(""));
        setFileList([]);
        return;
      }
    

    setShowOptions(true);
    setFileList(fileList);
  };

  img.onerror = () => {
    setError((prevError) => ({ ...prevError, imgerr: "Failed to load image." }));
    toast.error("Failed to load image.");
    dispatch(HandleS3BucketImage(""));
    setFileList([]);
  };
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowOptions(false);

    if (option === "custom") {
      setIsCaptionDisabled(false);
    } else {
      setIsCaptionDisabled(false);
      dispatch(
        GeneratedCaptionApi({
          email,
          referenceNumber,
          image: fileupload,
          dispatch,
          obj: {
            file: fileupload,
            file_name: fileupload.name,
            file_type: fileupload.type,
          },
          caption_type: option,
          custom_type: null,
        })
      ).then(() => {
        setError((prevError) => ({ ...prevError, image: undefined, imgsize: undefined, imgerr: undefined }));
      });
    }
  };

  const HandleChange = (e) => {
    setDetails({ ...details, [e.target.id]: e.target.value });
    setError({ ...error, [e.target.id]: undefined });
  };

  const Validation = () => {
    let errors = {};
    if (details?.caption === "") {
      errors.caption = "Required*";
    }

    if (fileupload === "" && fileupload?.length === 0) {
      errors.image = "Required*";
    }

    setError(errors);

    const hasErrors = Object.keys(errors).length > 0;
    if (hasErrors) {
      return true;
    } else {
      return false;
    }
  };

  // const Settingtext = "Settings for Auto minting";

  // const Infotext = `By default for autominting  Comment count is greater then: 200 & Multiple posts is greater then: 20 You can change the Comment count and multiple post count`;


  const [lastPostTimestamp, setLastPostTimestamp] = useState(null);

  useEffect(() => {
    const savedTimestamp = localStorage.getItem("lastPostTimestamp");
    if (savedTimestamp) {
      setLastPostTimestamp(parseInt(savedTimestamp, 10));
    }
  }, []);

  useEffect(() => {
    if (lastPostTimestamp) {
      const timeout = setTimeout(() => {
        setLastPostTimestamp(null);
        localStorage.removeItem("lastPostTimestamp");
      }, 30 * 60 * 1000);

      return () => clearTimeout(timeout);
    }
  }, [lastPostTimestamp]);


  const HandleSubmit = (e) => {
    e?.preventDefault();
    if (lastPostTimestamp && Date.now() - lastPostTimestamp < 30 * 60 * 1000) {
      toast.error("You can only post once every 30 minutes.");
      return;
    }

    if (fileupload && fileupload.size > 2 * 1024 * 1024) {
      setError({ ...error, imgsize: "File size must be less than 2MB." });
      toast.error("File size must be less than 2MB.");
      return;
    }

    if (fileupload && !fileupload.type.includes("image/jpeg")) {
      setError({ ...error, imgerr: "File format must be JPG." });
      toast.error("File format must be JPG.");
      return;
    }

    if (selectedOption === "custom" && details?.caption === "") {
      setError({ ...error, caption: "Required*" });
      toast.error("Caption is required for custom option.");
      return;
    }

    setFileupload("");
    setFileList([]);
    setDetails({
      username: "",
      password: "",
      caption: "",
    });

    let validData = Validation();

    if (validData) {
      return;
    } else {
      setFileupload("");
      setFileList([]);
      setDetails({
        username: "",
        password: "",
        caption: "",
      });

      let obj = {
        email: email,
        reference_number: referenceNumber,
        username: details?.username ? details?.username : decryptusername,
        password: details?.password ? details?.password : decryptpassword,
      };

      dispatch(
        UploadPostInstagram({
          instaonchangedata: details,
          fileupload,
          dispatch,
          email,
          referenceNumber,
          obj,
          TokenID,
          getUserWalletDetails,
          walletBalanceUser,
          caption: selectedOption === "custom" ? details.caption : ImageCaptionGenerate,
        })
      ).then(() => {
        setError({});
        const newTimestamp = Date.now();
        setLastPostTimestamp(newTimestamp);
        localStorage.setItem("lastPostTimestamp", newTimestamp.toString());
     
      }
    )

      // let removeimgvalue = (imageInputRef.current.value = "");
      // return removeimgvalue;
    }
  };

  const handleClearAll = () => {
    // Reset file upload state
    setFileupload("");

    // Reset the file list in the Upload component
    setFileList([]);

    // Reset caption and other details
    setDetails({
      username: decryptusername ? decryptusername : "",
      password: decryptpassword ? decryptpassword : "",
      caption: "",
    });

    // Clear any errors
    setError({});
    dispatch(HandleS3BucketImage(""));

    // Optionally, clear the S3BucketImage if it exists
    // Assuming S3BucketImage is part of the Redux store, dispatch an action to reset it
    // Example:
    // dispatch({ type: "CLEAR_S3_BUCKET_IMAGE" });
  };

  // const handleClickMintPost = (clickedPost) => {
  //   const domain = clickedPost?.permalink?.split("//")[1].split(".")[1];

  //   const callBack = (id) => {
  //     const postdetails = [
  //       {
  //         walletAddress: getUserWalletDetails,
  //         id: clickedPost?.id,
  //         postUrl: clickedPost?.share_link,
  //         platformType: domain,
  //         multiple: false,
  //         userTokenID: id,
  //         txnMethod: "Manual Add Post",
  //       },
  //     ];

  //     const callBackAddPost = () => {
  //       const obj = {
  //         email: email,
  //         reference_number: referenceNumber,
  //         password: GetDecryptPasswordformat(),
  //         username: GetDecryptUserNameformat(),
  //       };
  //       dispatch(GetInstagramUserDetailsInfo({obj,dispatch,TokenID: id,getUserWalletDetails,walletBalanceUser,
  //         })
  //       );
  //     };

  //     dispatch(AddPost({ postdetails, dispatch, callBack: callBackAddPost }));
  //   };

  //   const details = {
  //     email: email,
  //     reference_number: referenceNumber,
  //   };

  //   dispatch(GetTokenId({ obj: details }, callBack));
  // };

  const handleOpenInstaLink = (share_link) => {
    window.open(share_link, "AreaX", "height=500,width=500,left=450,top=100");
  };

  const items = [
    // tokenExist===0 &&
    // {
    //   label: "Allow Instagram Access",
    //   children: <AllowInstaAccess hasInstaAccess={tokenExist} />,
    // },

    // ...(tokenExist === 0
    //   ? [
          {
            label: "Allow Instagram Access",
            children: <AllowInstaAccess hasInstaAccess={tokenExist} />,
          },
        // ]
      // : []),
    // {
    //   label: "User Credentials",
    //   children: <SetupCredentialsForm />,
    // },
    {
      label: "Add New Post",
      children: (
        <div className="col-lg-12">
          <div className="card cus-m" style={{ borderRadius: "15px", width: "100%", maxWidth: "580px" }}>
            <div className="card-body p-4 insta-body">
              <div className="container123">
                <div className="form-container">
                  <h2 style={{ fontSize: "14px", fontWeight: "600" }} className="form-header">
                    Add New Post
                  </h2>
                  <form>


                    <div className="mb-3">
                      <div className="mb-2 d-flex gap-2">
                        <Upload
                          name="image"
                          className="Upload_image_css"
                          listType="picture"
                          maxCount={1}
                          accept=".jpg,.jpeg"
                          beforeUpload={() => false}
                          onChange={({ fileList }) => {
                            setFileList(fileList);
                            HandleFileChange(fileList);
                          }}
                          fileList={fileList}
                          onPreview={(file) => {
                            return false;
                          }}
                          itemRender={(originNode, file) => <div onClick={(e) => e.preventDefault()}>{originNode}</div>}
                        >
                          <Button className="upload_btn_css" icon={<UploadOutlined />} onClick={(e) => e.preventDefault()}>
                            Upload Image
                          </Button>
                        </Upload>
                      </div>

                      
                      {showOptions && (
                        <div className="options-container">
                          <Button onClick={() => handleOptionSelect("comment-focused")}>Comment Focused</Button>
                          <Button onClick={() => handleOptionSelect("like-driven")}>Like Driven</Button>
                          <Button onClick={() => handleOptionSelect("view-oriented")}>View Oriented</Button>
                          <Button onClick={() => handleOptionSelect("click-worthy")}>Click Worthy</Button>
                          <Button onClick={() => handleOptionSelect("custom")}>Custom</Button>
                        </div>
                      )}

                      <div className="my-3">
                        {S3BucketImage?.length > 0 && (
                          <>
                            <a href={S3BucketImage} target="_blank" rel="noopener noreferrer">
                              {S3BucketImage}
                            </a>
                          </>
                        )}
                      </div>
                      <p style={{ color: "red" }}>{error?.image}</p>
                      {error?.imgerr && <p style={{ color: "red" }}>{error?.imgerr}</p>}
                      {error?.imgsize && <p style={{ color: "red" }}>{error?.imgsize}</p>}
                      {error?.aspectRatio && <p style={{ color: "red" }}>{error?.aspectRatio}</p>}
                    </div>


                    <div className="mb-3">
                      <label htmlFor="caption" className="form-label">
                        Caption
                      </label>
                      <textarea
                        style={{
                          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          background: "#f5f7ff",
                        }}
                        className="form-control input_css_for_all_placeholder text_area_css__post"
                        id="caption"
                        value={details?.caption}
                        onChange={(e) => HandleChange(e)}
                        rows="6"
                        cols={50}
                        placeholder={isCaptionDisabled ? "Select 'Custom' to enter caption" : "Enter your caption"}
                        disabled={isCaptionDisabled}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            HandleSubmit(e);
                          }
                        }}
                      ></textarea>

                      {error?.caption && <p style={{ color: "red" }}>{error?.caption}</p>}
                    </div>
                    {/* {InstagramProfileData ? ( */}
                    <button
                      type="submit"
                      style={{
                        backgroundColor: "#17c1e8",
                        border: "none",
                        color: "white",
                        padding: "15px",
                        borderRadius: "4px",
                        height: "40px",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "10px",
                      }}
                      onClick={(e) => HandleSubmit(e)}
                    >
                      Post Image
                    </button>{" "}
                    <button
                      type="button"
                      style={{
                        backgroundColor: "#17c1e8",
                        border: "none",
                        color: "white",
                        padding: "15px",
                        borderRadius: "4px",
                        height: "40px",
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "10px",
                      }}
                      onClick={handleClearAll}
                    >
                      Clear All
                    </button>
                    {/* ) : (
                      "Disabled no user Posts"
                    )} */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      ),
    },
    {
      label: "Posts",
      // text={item.is_minted ? "Minted" : "NotMinted"}
      children: (
        <div className="tab-content">
          <div id="post" className="container">
            <div className="row mt-4">
              {InstagramProfileData?.length ? (
                <div className="flex-repet">
                  {InstagramProfileData?.slice(0, 3).map((item, index) => (
                    // <Badge.Ribbon color={item.is_minted ? "red" : "#17c1e8"} key={index} style={{ width: "32%" }}>
                    <div style={{ width: "32%", padding: "10px" }}>
                      <Tooltip title="Click to view post" color={"#17c1e8"} placement="top">
                        <Card
                          onClick={() => handleOpenInstaLink(item.permalink)}
                          hoverable
                          cover={
                            <img
                              alt="example"
                              src={item.media_url}
                              style={{
                                display: "block",
                                width: "auto",
                                height: "180px",
                                margin: "auto",
                                marginTop: "35px",
                              }}
                            />
                          }
                        >
                          <Meta title={<p>{item.caption}</p>} />
                          <div className="d-flex w-100 align-items-center mt-2 justify-content-between">
                            <div className="d-flex align-items-center gap-2 justify-content-between">
                              <div className="d-flex align-items-center gap-2 justify-content-between">
                                {item?.comments_count}
                                <i className="fa-solid fa-heart"></i>
                              </div>
                              <div className="d-flex align-items-center gap-2 justify-content-between">
                                {item.like_count}
                                <i className="fa-solid fa-comment"></i>
                              </div>
                            </div>
                            {/* {!item.is_minted && <Button onClick={() => handleClickMintPost(item)}>Mint</Button>} */}
                          </div>
                        </Card>
                      </Tooltip>
                    </div>
                    // </Badge.Ribbon>
                  ))}
                  <div className="d-flex w-100 justify-content-center">
                    <Link to="/igposts">See more Posts</Link>
                  </div>
                </div>
              ) : (
                <>
                  <h1 className="w-100 d-flex items-center justify-content-center">No posts available</h1>
                  {/* <p style={{ textAlign: "center" }}>Please enter the user's Instagram credentials first.</p> */}
                </>
              )}
            </div>
          </div>
        </div>
      ),
    },

    // {
    //   label: "Mint Transaction History",
    //   children: <TransactionHistory />,
    // },
  ];

  // const [open, setOpen] = useState(false);

  // const showDrawer = () => {
  //   setOpen(true);
  // };

  // const onClose = () => {
  //   setOpen(false);
  // };

  // const defaultSatte = {
  //   CommentCount: "",
  //   MultipleCount: "",
  //   isAutoMinting: false,
  // };

  // const [mintingData, setMintingData] = useState(defaultSatte);
  // const [showTooltip, setTooltipShow] = useState(false);

  // const [mintingError, setMintingError] = useState({
  //   CommentCount: "",
  //   MultipleCount: "",
  //   isAutoMinting: "",
  // });

  // const handleUpdateAutoMinting = (name, vaule) => {
  //   setMintingError((prevState) => ({ ...prevState, [name]: "" }));
  //   setMintingData((prevState) => ({ ...prevState, [name]: vaule }));
  // };

  // const handleSave = () => {
  //   // onClose();
  //   const isAlreadyEnabled = localStorage.getItem("isAutoMinting");
  //   if (isAlreadyEnabled && !mintingData.isAutoMinting) {
  //     localStorage.removeItem("CommentCount");
  //     localStorage.removeItem("MultipleCount");
  //     localStorage.removeItem("isAutoMinting");
  //     setMintingData(defaultSatte);
  //     return;
  //   }
  //   if (mintingData.isAutoMinting) {
  //     if (mintingData.CommentCount && mintingData.MultipleCount) {
  //       localStorage.setItem("CommentCount", mintingData.CommentCount);
  //       localStorage.setItem("MultipleCount", mintingData.MultipleCount);
  //       localStorage.setItem("isAutoMinting", mintingData.isAutoMinting);
  //       toast.success("Saved changes for autominting");
  //     } else {
  //       if (!mintingData.MultipleCount) {
  //         setMintingError((prevState) => ({
  //           ...prevState,
  //           MultipleCount: "Please enter the value of multiple Post count",
  //         }));
  //       }
  //       if (!mintingData.CommentCount) {
  //         setMintingError((prevState) => ({
  //           ...prevState,
  //           CommentCount: "Please enter the value of Comment count",
  //         }));
  //       }
  //     }
  //   } else {
  //     setMintingError((prevState) => ({
  //       ...prevState,
  //       isAutoMinting: "Please Check the toggle",
  //     }));
  //     setTimeout(() => {
  //       setMintingError((prevState) => ({ ...prevState, isAutoMinting: "" }));
  //     }, 3000);
  //   }
  // };

  // useEffect(() => {
  //   let CommentCount = localStorage.getItem("CommentCount");
  //   let MultipleCount = localStorage.getItem("MultipleCount");
  //   let isAutoMinting = localStorage.getItem("isAutoMinting");
  //   if (isAutoMinting && CommentCount && MultipleCount) {
  //     setMintingData({ CommentCount, MultipleCount, isAutoMinting });
  //   }
  // }, []);

  

  return (
    <div className="container">
      <div>
        {/* <span style={{ color: "red" }}>
          {walletBalanceUser == "You have not enough balance 0.,please add more balance." ? "You have not enough balance.Please add more balance for autominting." : ""}{" "}
        </span> */}
      </div>
      <div className="row">
        <div className="top-section col-lg-3">
          <div className="top-img d-flex justify-content-center">
            <img
              src={base64Image ? base64Image : "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-profiles/avatar-1.webp"}
              alt="Profile Picture"
              className="rounded-circle profile-pic"
            />
          </div>
        </div>

        <div className="profile-name col-lg-8" style={{ paddingTop: "55px" }}>
          <div className="d-flex items-center">
            <h3 className="profile-detail mt-3">{InstagramUserDetails?.username ? InstagramUserDetails?.username : ""}</h3>
            {/* <Flex vertical justify="center" align="center" className="demo">
              <Flex
                justify="center"
                align="center"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                <Tooltip placement="bottom" title={Settingtext}>
                  <div>
                    <i className="fa-solid fa-gear mx-3" style={{ cursor: "pointer" }} onClick={showDrawer}></i>
                  </div>
                </Tooltip>
              </Flex>
            </Flex> */}

            {/* <Drawer title="Update Auto Minting" onClose={onClose} open={open}>
              <div className="my-2">
                <Flex vertical align="center" direction="row" className="demo">
                  <Flex
                    align="center"
                    style={{
                      whiteSpace: "nowrap",
                      gap: "10px",
                    }}
                  >
                    <Space direction="vertical">
                      <Tooltip
                        open={mintingError.isAutoMinting || showTooltip}
                        placement="top"
                        title={
                          mintingError.isAutoMinting
                            ? mintingError.isAutoMinting
                            : showTooltip && mintingData.isAutoMinting
                            ? "Click to disable auto minting"
                            : "Click to enable auto minting"
                        }
                      >
                        <Switch
                          onChange={(checked) => handleUpdateAutoMinting("isAutoMinting", checked)}
                          checkedChildren={<i className="fa-solid fa-check"></i>}
                          unCheckedChildren={<i className="fa-solid fa-xmark"></i>}
                          defaultChecked={mintingData.isAutoMinting}
                          onMouseEnter={() => setTooltipShow(true)}
                          onMouseLeave={() => setTooltipShow(false)}
                        />
                      </Tooltip>
                    </Space>
                    <Tooltip placement="bottom" title={Infotext}>
                      <div>
                        <i className="fa-solid fa-circle-info"></i>
                      </div>
                    </Tooltip>
                    <Button type="dashed" onClick={() => handleSave()}>
                      Save Changes
                    </Button>
                  </Flex>
                </Flex>
              </div>
              <div className="my-3">
                Enter the Comment Count to store Posts to the blockchain
                <input
                  type="number"
                  className="form-control input_css_for_all_placeholder"
                  onChange={(e) => handleUpdateAutoMinting("CommentCount", e.target.value)}
                  value={mintingData.CommentCount}
                  placeholder="Enter your comment count"
                  min={0}
                />
                <span style={{ color: "red" }}>{mintingError.CommentCount}</span>
              </div>
              <div className="my-3">
                Enter the Count to store Multiple Posts to the blockchain
                <input
                  type="number"
                  className="form-control input_css_for_all_placeholder"
                  onChange={(e) => handleUpdateAutoMinting("MultipleCount", e.target.value)}
                  value={mintingData.MultipleCount}
                  placeholder="Enter your multiple post count"
                  min={0}
                />
                <span style={{ color: "red" }}>{mintingError.MultipleCount}</span>
              </div>
            </Drawer> */}
          </div>
          <ul className="text-muted">
            <li className="text-list">
              {" "}
              <span style={{ fontWeight: 600, color: "black" }}>{InstagramUserDetails?.media_count ? InstagramUserDetails?.media_count : 0}</span> posts
            </li>
            <li className="text-list">
              <span style={{ fontWeight: 600, color: "black" }}>{typeof InstagramProfileData?.followersCount === "number" ? InstagramProfileData?.followersCount : 0} </span>
              followers
            </li>
            <li className="text-list">
              {" "}
              <span style={{ fontWeight: 600, color: "black" }}>{InstagramUserDetails?.account_type ? InstagramUserDetails?.account_type : ""}</span> Account Type
            </li>
          </ul>
        </div>
      </div>
      <div className="container mt-4">
        <Tabs
          type="card"
          defaultActiveKey={defaultTab}
          activeKey={activeTab}
          onChange={handleTabChange}
          items={items.map((item, i) => {
            const id = String(i + 1);
            return {
              key: id,
              label: item.label,
              children: item.children,
            };
          })}
        />
      </div>
    </div>
  );
}
