import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Button, InputNumber, Select, Tabs, Tooltip as AntTooltip, Modal } from "antd";
import {
  GeminiBillingCost,
  GetbillingStatusDATA,
  getGeminiCostGraph,
  GetGeminiLimit,
  GetOpenAIandGeminiUserDataSave,
  getOpenAiCostGraph,
  GetOpenAiLimit,
  getServerCostDataAws,
  getServerCostGraph,
  GetServerDailyCost,
  getStabilityCostGraph,
  GetUserLimit,
  getUserStabilityDiffusionLimitSet,
  OpenAPIBillingCost,
  SetGeminiLimit,
  SetOpenAiLimit,
  SetUserLimit,
  setuserStabilityDiffusionLimitSet,
  StabilityDiffusionCost,
} from "../../redux/auth/authSlice";
import { Progress } from "antd";
import Loader from "../../Components/Loader/Loader";
import "../../style/billing.css";
import { Line } from "react-chartjs-2";
import { Table } from "antd";
import { DatePicker } from "antd";

import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement } from "chart.js";
import { DecryptedDataAdminlocalStorage } from "../../Components/Common/Common";

ChartJS.register(LineElement, CategoryScale, LinearScale, Title, Tooltip, Legend, PointElement);

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const BillingStatusAdmin = () => {
  const {
    isLoading,
    ServerDailyCost,
    CostGraph,
    CostGraphOpenAi,
    CostGraphStability,
    CostGraphGemini,
    BillingCostOpenAI,
    userlimitdata,
    StabilityDiffusion,
    BillingCostGemini,
    OpenAiLimit,
    StabilityLimit,
    GeminiLimit,
    BillingCostLLM,
    UsageLLMUser,
    AwsCostUsage,
  } = useSelector((state) => state.counter);
  const dispatch = useDispatch();
  const { Option } = Select;
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("1");
  const [currentYearAWS, setCurrentYearAWS] = useState(new Date().getFullYear());
  const [currentYearUserReport, setCurrentYearUserReport] = useState(new Date().getFullYear());
  const [currentMonthDataReport, setCurrentMonthDataReport] = useState("");
  const [currentYearOpenAI, setCurrentYearOpenAI] = useState(new Date().getFullYear());
  const [currentYearStability, setCurrentYearStability] = useState(new Date().getFullYear());
  const [currentYearGemini, setCurrentYearGemini] = useState(new Date().getFullYear());
  const [currentYearHugging, setCurrentYearHugging] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  const [serverCostData, setServerCostData] = useState(null);
  const [AwsCostValue, setAwsCostValue] = useState(null);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [OpenAiCostGraph, setOpenAiCostGraph] = useState(null);
  const [StabilityCostGraph, setStabilityCostGraph] = useState(null);
  const [GeminiCostGraph, setGeminiCostGraph] = useState(null);
  const [error, setError] = useState({});
  const [awsDataUsage, setAwsDataUsage] = useState("");
  const [fromAndToDate, setFromAndToDate] = useState(["", ""]);
  const [UsageLLMUserData, setUsageLLMUserData] = useState([]);
  const [BillingCostLLMData, setBillingCostLLMData] = useState([]);
  const [HuggingCostGraph, setHuggingCost] = useState({
    January: 0,
    February: 0,
    March: 0,
    April: 0,
    May: 0,
    June: 0,
    July: 0,
    August: 0,
    September: 0,
    October: 0,
    November: 0,
    December: 0,
  });
  const [BillingData, setBillingData] = useState({
    aws: "",
    gemini: "",
    huggingface: "",
    openai: "",
    stability: "",
    flux: "",
    llama: "",
  });
  const [isSubmitModalVisible, setIsSubmitModalVisible] = useState(false);
  const [submitType, setSubmitType] = useState(null);
  const monthNamesData = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const currentMonthIndexData = new Date().getMonth();
  const currentMonthDataUser = monthNamesData[currentMonthIndexData];

  const awsUnit = ServerDailyCost?.Unit || "USD";

  const openAIAmount = BillingCostOpenAI?.total_cost || 0;
  const openAIUnit = BillingCostOpenAI?.Unit || "USD";

  const stabilityDiffusionCost = StabilityDiffusion?.total_cost_in_dollar || 0;
  const stabilityDiffusionUnit = StabilityDiffusion?.Unit || "USD";

  const geminiData = BillingCostGemini?.total_cost || "0";
  const geminiUnit = BillingCostGemini?.Unit || "USD";

  const generateChartData = (data, unit) => {
    const labels = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const chartData = labels.map((month) => parseFloat(data[month]) || 0);
    return {
      labels,
      datasets: [
        {
          label: `Costs (${unit})`,
          data: chartData,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderWidth: 2,
          fill: true,
        },
      ],
    };
  };

  

  const awsData = generateChartData(serverCostData || {}, awsUnit);
  const openAIData = generateChartData(OpenAiCostGraph || {}, openAIUnit);

  const stabilityDiffusionData = generateChartData(StabilityCostGraph || {}, stabilityDiffusionUnit);
  const geminiCost = generateChartData(GeminiCostGraph || {}, geminiUnit);
  const huggingcost = generateChartData(HuggingCostGraph || {}, "USD");

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, 
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: $${context.raw}`,
        },
      },
       title: {
        display: true,
        text: "Monthly Register Users",
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: `Cost (${awsUnit})`,
        },
        ticks: {
          callback: (value) => `$${value}`,
        },
      },
    },
  };

  const getChartOptions = (unit, title) => ({
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hide the legend
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: $${context.raw}`,
        },
      },
      title: {
        display: true, // Enable dynamic title display
        text: title,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: `Cost (${unit})`,
        },
        ticks: {
          callback: (value) => `$${value}`,
        },
      },
    },
  });
  

  const columnsforfilter = [
    {
      title: "Services",
      dataIndex: "name",
      key: "name",
      align: "start",
      className: "d-flex",
      render: (name) => <p style={{ fontWeight: name === "Total" ? "bold" : "normal" }}>{name}</p>,
    },
    {
      title: "Cost",
      dataIndex: "total_cost",
      key: "total_cost",
      align: "start",
      render: (total_cost, a) => {
        return <a style={{ fontWeight: a.name === "Total" ? "bold" : "normal" }}>{total_cost}</a>;
      },
    },
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
      align: "start",
      render: (month, a) => {
        const displayMonth = month || (a.startmonth && a.endmonth ? `${a.startmonth} - ${a.endmonth}` : "");
        return <a style={{ fontWeight: a.name === "Total" ? "bold" : "normal" }}>{displayMonth}</a>;
      },
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "year",
      align: "start",
      render: (year, a) => {
        const displayYear = year || (a.startyear && a.endyear ? `${a.startyear} - ${a.endyear}` : "");
        return <a style={{ fontWeight: a.name === "Total" ? "bold" : "normal" }}>{displayYear}</a>;
      },
    },
  ];

  const columnsforfilterUserReport = [
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "left",
      render: (name) => <p style={{ fontWeight: name === "Total" ? "bold" : "normal", textAlign: "left", margin: 0 }}>{name}</p>,
    },
    {
      title: "Services",
      dataIndex: "service",
      key: "service",
      align: "left",
      render: (service) => <p style={{ fontWeight: service === "Total" ? "bold" : "normal", textAlign: "left", margin: 0 }}>{service}</p>,
    },
    {
      title: "Overall Cost Usage",
      dataIndex: "total_cost",
      key: "total_cost",
      align: "left",
      render: (total_cost) => <p style={{ fontWeight: total_cost === "Total" ? "bold" : "normal", textAlign: "left", margin: 0 }}>{total_cost}</p>,
    },
  ];

  const totalPercentUsedAWS = (parseFloat(awsDataUsage) / parseFloat(userlimitdata)) * 100;
  const totalPercentUsedOpenAI = (parseFloat(openAIAmount) / parseFloat(OpenAiLimit)) * 100;
  const totalPercentUsedStability = (parseFloat(stabilityDiffusionCost) / parseFloat(StabilityLimit)) * 100;
  const totalPercentGemini = (parseFloat(geminiData) / parseFloat(GeminiLimit)) * 100;

  useEffect(() => {
    const service = location.state?.service;
    if (service) {
      switch (service) {
        case "AWS":
          setActiveTab("1");
          break;
        case "Open AI":
          setActiveTab("2");
          break;
        case "Stability Diffusion":
          setActiveTab("3");
          break;
        case "Gemini AI":
          setActiveTab("4");
          break;
        case "Hugging Face":
          setActiveTab("5");
          break;
        case "Flux":
          setActiveTab("6");
          break;
        case "Llama 3.2":
          setActiveTab("7");
          break;
        default:
          setActiveTab("1");
      }
    }
  }, [location.state]);

  useEffect(() => {
    function getCurrentMonthData(data) {
      const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

      const currentMonthIndex = new Date().getMonth();
      const currentMonth = monthNames[currentMonthIndex];

      return data ? data[currentMonth] : null;
    }

    if (serverCostData) {
      const currentMonthData = getCurrentMonthData(serverCostData);
      setAwsCostValue(currentMonthData);
    }
  }, [serverCostData]);

  useEffect(() => {
    const body = {
      user_id: DecryptedDataAdminlocalStorage(localStorage.getItem("email")),
    };
    dispatch(OpenAPIBillingCost({ dispatch }));
    dispatch(GetServerDailyCost({ dispatch }));
    dispatch(GeminiBillingCost({ dispatch }));
    dispatch(StabilityDiffusionCost({ dispatch }));

    dispatch(GetUserLimit({ dispatch, body }));
    dispatch(GetOpenAiLimit({ dispatch, body }));
    dispatch(getUserStabilityDiffusionLimitSet({ dispatch, body }));
    dispatch(GetGeminiLimit({ dispatch, body }));
    dispatch(GetOpenAIandGeminiUserDataSave({ dispatch }));
    dispatch(getServerCostDataAws({ dispatch }));

    if (UsageLLMUser) {
      setUsageLLMUserData(UsageLLMUser);
    }

    const initialYears = Array.from({ length: 2 }, (_, i) => new Date().getFullYear() - i);
    setYears(initialYears);

    setCurrentMonthDataReport(currentMonthDataUser);
  }, [dispatch]);

  useEffect(() => {
    if (AwsCostUsage) {
      setAwsDataUsage(AwsCostUsage?.total_cost);
    }
  }, [AwsCostUsage]);

  useEffect(() => {
    if (currentYearAWS) {
      const fetchData = async () => {
        const data = await dispatch(getServerCostGraph({ currentYearAWS }));

        if (data && currentYearAWS) {
          setServerCostData(data.data);
        }
      };
      fetchData();
    }
  }, [dispatch, currentYearAWS]);

  useEffect(() => {
    if (CostGraph?.data) {
      setServerCostData(CostGraph.data);
    }
  }, [CostGraph]);

  useEffect(() => {
    if (CostGraphOpenAi?.data) {
      setOpenAiCostGraph(CostGraphOpenAi.data);
    }
  }, [CostGraphOpenAi]);

  useEffect(() => {
    if (CostGraphStability?.data) {
      setStabilityCostGraph(CostGraphStability.data);
    }
  }, [CostGraphStability]);

  useEffect(() => {
    if (CostGraphGemini?.data) {
      setGeminiCostGraph(CostGraphGemini.data);
    }
  }, [CostGraphGemini]);

  useEffect(() => {
    if (currentYearOpenAI) {
      const fetchData = async () => {
        const data = await dispatch(getOpenAiCostGraph({ currentYearOpenAI }));
        if (data && currentYearOpenAI) {
          setOpenAiCostGraph(data.data);
        }
      };
      fetchData();
    }
  }, [dispatch, currentYearOpenAI]);

  useEffect(() => {
    if (currentYearStability) {
      const fetchData = async () => {
        const data = await dispatch(getStabilityCostGraph({ currentYearStability }));
        if (data && currentYearStability) {
          setStabilityCostGraph(data.data);
        }
      };
      fetchData();
    }
  }, [dispatch, currentYearStability]);

  useEffect(() => {
    if (currentYearGemini) {
      const fetchData = async () => {
        const data = await dispatch(getGeminiCostGraph({ currentYearGemini }));
        if (data && currentYearGemini) {
          setGeminiCostGraph(data.data);
        }
      };
      fetchData();
    }
  }, [dispatch, currentYearGemini]);

  useEffect(() => {
    setHuggingCost((prevstate) => ({
      ...prevstate,
      October: 9,
    }));
  }, []);

  const fetchBillingStatus = useCallback(() => {
    dispatch(GetbillingStatusDATA({ dispatch, fromAndToDate }));
  }, [dispatch, fromAndToDate]);

  useEffect(() => {
    fetchBillingStatus();
  }, [fetchBillingStatus]);

  useEffect(() => {
    if (BillingCostLLM) {
      setBillingCostLLMData(BillingCostLLM);
    }
  }, [BillingCostLLM]);

  const handleYearChangeUserReport = (value) => {
    setCurrentYearUserReport(value);
    if (currentYearUserReport) {
      dispatch(GetOpenAIandGeminiUserDataSave({ dispatch, currentYearUserReport }));
    }
  };

  const fetchUserReport = useCallback(() => {
    if (currentYearUserReport && currentMonthDataReport) {
      dispatch(GetOpenAIandGeminiUserDataSave({ dispatch, currentYearUserReport, currentMonthDataReport }));
    }
  }, [dispatch, currentYearUserReport, currentMonthDataReport]);

  useEffect(() => {
    fetchUserReport();
  }, [fetchUserReport]);

  useEffect(() => {
    if (UsageLLMUser) {
      setUsageLLMUserData(UsageLLMUser);
    }
  }, [UsageLLMUser]);

 

  const HandleChange = (name, value) => {
    setBillingData({
      ...BillingData,
      [name]: value,
    });

    if (value !== "") {
      setError((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const Validation = (field) => {
    const newErrors = {};
    if (field === "aws" && !BillingData.aws) {
      newErrors.aws = "Amount field is required";
    }
    if (field === "openai" && !BillingData.openai) {
      newErrors.openai = "Amount field is required";
    }
    if (field === "stability" && !BillingData.stability) {
      newErrors.stability = "Amount field is required";
    }
    if (field === "gemini" && !BillingData.gemini) {
      newErrors.gemini = "Amount field is required";
    }
    if (field === "huggingface" && !BillingData.huggingface) {
      newErrors.huggingface = "Amount field is required";
    }
    if (field === "flux" && !BillingData.flux) {
      newErrors.flux = "Amount field is required";
    }
    if (field === "llama" && !BillingData.llama) {
      newErrors.llama = "Amount field is required";
    }
    setError(newErrors);
    return Object.keys(newErrors).length > 0;
  };

  const HandleSubmit = (e) => {
    e?.preventDefault();
    if (Validation("aws")) return;
    const obj = {
      user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
      limit: BillingData.aws,
    };
    setBillingData((prev) => ({ ...prev, aws: "" }));
    dispatch(SetUserLimit({ obj, dispatch }));
  };

  const HandleSubmitOpen = (e) => {
    e?.preventDefault();
    if (Validation("openai")) return;
    const obj = {
      user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
      limit: BillingData.openai,
    };
    setBillingData((prev) => ({ ...prev, openai: "" }));
    dispatch(SetOpenAiLimit({ obj, dispatch }));
  };

  const HandleSubmitStability = (e) => {
    e?.preventDefault();
    if (Validation("stability")) return;
    const obj = {
      user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
      limit: BillingData.stability,
    };
    setBillingData((prev) => ({ ...prev, stability: "" }));
    dispatch(setuserStabilityDiffusionLimitSet({ obj, dispatch }));
  };
  const HandleSubmitGemini = (e) => {
    e?.preventDefault();
    if (Validation("gemini")) return;
    const obj = {
      user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
      limit: BillingData.gemini,
    };
    setBillingData((prev) => ({ ...prev, gemini: "" }));
    dispatch(SetGeminiLimit({ obj, dispatch }));
  };

  const HandleSubmitHuggingFace = (e) => {
    e?.preventDefault();
    if (Validation("huggingface")) return;
    const obj = {
      user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
      limit: BillingData.huggingface,
    };
    setBillingData((prev) => ({ ...prev, huggingface: "" }));
  };

  const HandleSubmitFlux = (e) => {
    e?.preventDefault();
    if (Validation("flux")) return;
    const obj = {
      user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
      limit: BillingData.flux,
    };
    setBillingData((prev) => ({ ...prev, flux: "" }));
  };

  const HandleSubmitLlama = (e) => {
    e?.preventDefault();
    if (Validation("llama")) return;
    const obj = {
      user_id: DecryptedDataAdminlocalStorage(localStorage?.getItem("email")),
      limit: BillingData.llama,
    };
    setBillingData((prev) => ({ ...prev, llama: "" }));
  };

  const handleYearChangeAWS = (value) => {
    setCurrentYearAWS(value);
  };

  const handleYearChangeOpenAI = (value) => {
    setCurrentYearOpenAI(value);
  };

  const handleYearChangeStability = (value) => {
    setCurrentYearStability(value);
  };

  const handleYearChangeGemini = (value) => {
    setCurrentYearGemini(value);
  };
  const handleYearChangeHugging = (value) => {
    setCurrentYearHugging(value);
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleDateChange = (dates, dateStrings) => {
    setFromAndToDate(dateStrings);
  };

  const handleMonthChangeUserReport = (value) => {
    setCurrentMonthDataReport(value);
    if (currentMonthDataReport) {
      dispatch(GetOpenAIandGeminiUserDataSave({ dispatch, currentMonthDataReport }));
    }
  };

  const convertToCSV = (data) => {
    const headers = Object.keys(data[0]).join(",") + "\n";
    const rows = data.map((row) => Object.values(row).join(",")).join("\n");
    return headers + rows;
  };

  const downloadCSV = (csvData) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "data.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  };

  const handleDownloadCSV = () => {
    const data = BillingCostLLMData.map((item) => {
      const { status, statuscode, ...rest } = item;
      return { ...rest };
    });

    const csv = convertToCSV(data);
    downloadCSV(csv);
  };

  const handleDownloadCSV1 = () => {
    const data = UsageLLMUserData.map((item) => {
      const { reference_number, usage_count, created_at, ...rest } = item;
      return { ...rest };
    });

    const csv = convertToCSV(data);
    downloadCSV(csv);
  };


  const showSubmitModal = (type) => {
    if (Validation(type)) {
      return;
    }
    setSubmitType(type);
    setIsSubmitModalVisible(true);
  };

  const handleConfirmSubmit = () => {
    setIsSubmitModalVisible(false);
    switch (submitType) {
      case "aws":
        HandleSubmit();
        break;
      case "openai":
        HandleSubmitOpen();
        break;
      case "stability":
        HandleSubmitStability();
        break;
      case "gemini":
        HandleSubmitGemini();
        break;
        case "huggingface":
        HandleSubmitHuggingFace();
        break;
      case "flux":
        HandleSubmitFlux();
        break;
      case "llama":
        HandleSubmitLlama();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="billing-container">
        <h2 className="main-txt" style={{ color: "#17c1e8" }}>
          Billing Statistics
        </h2>

        <Tabs defaultActiveKey={activeTab} className="billing-tabs" activeKey={activeTab} onChange={(key) => setActiveTab(key)}>
          <TabPane tab="AWS" key="1">
            <div className="aws-tab-content">
              <div className="info-section">
                <AntTooltip placement="top" title="When your limit is reached, you will receive a notification in your email.">
                  <i className="fa-solid fa-circle-info mx-2"></i>
                </AntTooltip>
                Your threshold Amount is: <span style={{ fontWeight: 600 }}>{userlimitdata}$ USD</span>
              </div>
              <h4>AWS Billing Details</h4>

              <Progress
                percent={totalPercentUsedAWS}
                percentPosition={{
                  align: "center",
                  type: "outer",
                }}
                format={(percent) => {
                  const isOverLimit = parseFloat(awsDataUsage) >= parseFloat(userlimitdata);
                  const textStyle = { color: isOverLimit ? "red" : "inherit" };

                  return <span className="resp_progress_css" style={textStyle}>{`${parseFloat(awsDataUsage ? awsDataUsage : "0")} ${awsUnit} used out of $${userlimitdata}`}</span>;
                }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "red",
                }}
                size={[400, 10]}
              />

              <p>
                <strong>AWS Cost:</strong> {AwsCostValue} {awsUnit}
              </p>
              <div className="input-section">
                <InputNumber
                  min={0}
                  value={BillingData.aws}
                  placeholder="Enter amount"
                  name="aws"
                  addonAfter="$"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") HandleSubmit(e);
                  }}
                  onChange={(value) => HandleChange("aws", value)}
                  
                />
                <Button type="primary" className="mx-3" onClick={() => showSubmitModal("aws")}>
                  Submit
                </Button>

                <Select defaultValue={currentYearAWS} onChange={handleYearChangeAWS} style={{ width: 120, marginLeft: "16px" }}>
                  {years.map((year) => (
                    <Option key={year} value={year}>
                      {year}
                    </Option>
                  ))}
                </Select>
              </div>
              {error.aws && <p style={{ color: "red" }}>{error.aws}</p>}
              <div className="chart">
            
                <Line data={awsData}   options={getChartOptions(openAIUnit, "AWS Billing Graph")} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Open AI" key="2">
            <div className="open-tab-content">
              <div className="info-section">
                <AntTooltip placement="top" title="When your limit is reached, you will receive a notification in your email.">
                  <i className="fa-solid fa-circle-info mx-2"></i>
                </AntTooltip>
                Your threshold Amount is: <span style={{ fontWeight: 600 }}>{OpenAiLimit}$ USD</span>
              </div>
              <h4>Open AI Billing Details</h4>
              <Progress
                percent={totalPercentUsedOpenAI}
                percentPosition={{
                  align: "center",
                  type: "outer",
                }}
                format={(percent) => {
                  const isOverLimit = parseFloat(openAIAmount) >= parseFloat(OpenAiLimit);
                  const textStyle = { color: isOverLimit ? "red" : "inherit" };

                  return <span className="resp_progress_css" style={textStyle}>{`${parseFloat(openAIAmount)} ${openAIUnit} used out of $${OpenAiLimit}`}</span>;
                }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "red",
                }}
                size={[400, 10]}
              />
              <p>
                <strong>Open AI Cost:</strong> {openAIAmount} {openAIUnit}
              </p>
              <InputNumber
                min={0}
                value={BillingData.openai}
                placeholder="Enter amount"
                name="openai"
                addonAfter="$"
                onKeyDown={(e) => {
                  if (e.key === "Enter") HandleSubmitOpen(e);
                }}
                onChange={(value) => HandleChange("openai", value)}
              />
              <Button type="primary" className="mx-3" onClick={() => showSubmitModal("openai")}>
                Submit
              </Button>
              <Select defaultValue={currentYearOpenAI} onChange={handleYearChangeOpenAI} style={{ width: 120, marginLeft: "16px" }}>
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              {error.openai && <p style={{ color: "red" }}>{error.openai}</p>}
              <div className="chart">
                <Line data={openAIData} options={getChartOptions(openAIUnit, "OpenAI Billing Graph")} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Stability Diffusion" key="3">
            <div className="stability-diffusion-tab-content">
              <div className="info-section">
                <AntTooltip placement="top" title="When your limit is reached, you will receive a notification in your email.">
                  <i className="fa-solid fa-circle-info mx-2"></i>
                </AntTooltip>
                Your threshold Amount is: <span style={{ fontWeight: 600 }}>{StabilityLimit}$ USD</span>
              </div>
              <h4>Stability Diffusion Billing Details</h4>
              <Progress
                percent={totalPercentUsedStability}
                percentPosition={{
                  align: "center",
                  type: "outer",
                }}
                format={(percent) => {
                  const isOverLimit = parseFloat(stabilityDiffusionCost) >= parseFloat(StabilityLimit);
                  const textStyle = { color: isOverLimit ? "red" : "inherit" };

                  return (
                    <span className="resp_progress_css" style={textStyle}>{`${parseFloat(stabilityDiffusionCost)} ${stabilityDiffusionUnit} used out of $${StabilityLimit}`}</span>
                  );
                }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "red",
                }}
                size={[400, 10]}
              />
              <p>
                <strong>Stability Diffusion Cost:</strong> {stabilityDiffusionCost} {stabilityDiffusionUnit}
              </p>
              <InputNumber
                min={0}
                value={BillingData.stability}
                placeholder="Enter amount"
                name="stability"
                addonAfter="$"
                onKeyDown={(e) => {
                  if (e.key === "Enter") HandleSubmitStability(e);
                }}
                onChange={(value) => HandleChange("stability", value)}
              />
              <Button type="primary" className="mx-3" onClick={() => showSubmitModal("stability")}>
                Submit
              </Button>
              <Select defaultValue={currentYearStability} onChange={handleYearChangeStability} style={{ width: 120, marginLeft: "16px" }}>
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              {error.stability && <p style={{ color: "red" }}>{error.stability}</p>}
              <div className="chart">
                <Line data={stabilityDiffusionData} options={getChartOptions(openAIUnit, "Stability Billing Graph")} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Gemini AI" key="4">
            <div className="gemini-ai-tab-content">
              <div className="info-section">
                <AntTooltip placement="top" title="When your limit is reached, you will receive a notification in your email.">
                  <i className="fa-solid fa-circle-info mx-2"></i>
                </AntTooltip>
                Your threshold Amount is: <span style={{ fontWeight: 600 }}>{GeminiLimit}$ USD</span>
              </div>
              <h4>Gemini AI Billing Details</h4>

              <Progress
                percent={totalPercentGemini}
                percentPosition={{
                  align: "center",
                  type: "outer",
                }}
                format={(percent) => {
                  const isOverLimit = parseFloat(geminiData) >= parseFloat(GeminiLimit);
                  const textStyle = { color: isOverLimit ? "red" : "inherit" };
                  return <span className="resp_progress_css" style={textStyle}>{`${parseFloat(geminiData.replace("$", ""))} ${geminiUnit} used out of $${GeminiLimit}`}</span>;
                }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "red",
                }}
                size={[400, 10]}
              />
              <p>
                <strong>Gemini AI Cost:</strong> {geminiData} {geminiUnit}
              </p>
              <InputNumber
                min={0}
                value={BillingData.gemini}
                placeholder="Enter amount"
                name="gemini"
                addonAfter="$"
                onKeyDown={(e) => {
                  if (e.key === "Enter") HandleSubmitGemini(e);
                }}
                onChange={(value) => HandleChange("gemini", value)}
              />
              <Button type="primary" className="mx-3" onClick={() => showSubmitModal("gemini")}>
                Submit
              </Button>
              <Select defaultValue={currentYearGemini} onChange={handleYearChangeGemini} style={{ width: 120, marginLeft: "16px" }}>
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              {error.gemini && <p style={{ color: "red" }}>{error.gemini}</p>}
              <div className="chart">
                <Line data={geminiCost} options={getChartOptions(openAIUnit, "Gemini Billing Graph")} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="HuggingFace" key="5">
            <div className="huggingface-tab-content">
              <div className="info-section">
                <AntTooltip placement="top" title="When your limit is reached, you will receive a notification in your email.">
                  <i className="fa-solid fa-circle-info mx-2"></i>
                </AntTooltip>
                Your threshold Amount is: <span style={{ fontWeight: 600 }}>{}$20 USD</span>
              </div>
              <h4>HuggingFace Billing Details</h4>
              <Progress
                percent={9}
                percentPosition={{
                  align: "center",
                  type: "outer",
                }}
                format={(percent) => {
                  return <span className="resp_progress_css">{`${parseFloat(9)} ${"USD"} used out of $${20}`}</span>;
                }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "red",
                }}
                size={[400, 10]}
              />
              <p>
                <strong>
                  Hugging Face Cost: <span style={{ fontWeight: "400" }}>$9 USD</span>
                </strong>
              </p>
              <InputNumber
                min={0}
                value={BillingData.huggingface}
                placeholder="Enter amount"
                name="huggingface"
                addonAfter="$"
                onChange={(value) => HandleChange("huggingface", value)}
              />
              <Button type="primary" className="mx-3" onClick={() => showSubmitModal("huggingface")}>
                Submit
              </Button>
              <Select defaultValue={currentYearHugging} onChange={handleYearChangeHugging} style={{ width: 120, marginLeft: "16px" }}>
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              {error.huggingface && <p style={{ color: "red" }}>{error.huggingface}</p>}
              <div className="chart">
                <Line data={huggingcost} options={options} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Flux" key="6">
            <div className="huggingface-tab-content">
              <div className="info-section">
                <AntTooltip placement="top" title="When your limit is reached, you will receive a notification in your email.">
                  <i className="fa-solid fa-circle-info mx-2"></i>
                </AntTooltip>
                Your threshold Amount is: <span style={{ fontWeight: 600 }}>{}$0 USD</span>
              </div>
              <h4>Flux Billing Details</h4>
              <Progress
                percent={9}
                percentPosition={{
                  align: "center",
                  type: "outer",
                }}
                format={(percent) => {
                  return <span className="resp_progress_css">{`${parseFloat(0)} ${"USD"} used out of $${0}`}</span>;
                }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "red",
                }}
                size={[400, 10]}
              />
              <p>
                <strong>
                  Flux Cost: <span style={{ fontWeight: "400" }}>$0 USD</span>
                </strong>
              </p>
              <InputNumber min={0} value={BillingData.flux} placeholder="Enter amount" name="flux" addonAfter="$" onChange={(value) => HandleChange("flux", value)} />
              <Button type="primary" className="mx-3" onClick={() => showSubmitModal("flux")}>
                Submit
              </Button>
              <Select defaultValue={currentYearHugging} onChange={handleYearChangeHugging} style={{ width: 120, marginLeft: "16px" }}>
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              {error.flux && <p style={{ color: "red" }}>{error.flux}</p>}
              <div className="chart">
                <Line data={stabilityDiffusionData} options={options} />
              </div>
            </div>
          </TabPane>

          <TabPane tab="Llama 3.2" key="7">
            <div className="huggingface-tab-content">
              <div className="info-section">
                <AntTooltip placement="top" title="When your limit is reached, you will receive a notification in your email.">
                  <i className="fa-solid fa-circle-info mx-2"></i>
                </AntTooltip>
                Your threshold Amount is: <span style={{ fontWeight: 600 }}>{}$0 USD</span>
              </div>
              <h4>Llama 3.2 Billing Details</h4>
              <Progress
                percent={9}
                percentPosition={{
                  align: "center",
                  type: "outer",
                }}
                format={(percent) => {
                  return <span className="resp_progress_css">{`${parseFloat(0)} ${"USD"} used out of $${0}`}</span>;
                }}
                strokeColor={{
                  "0%": "#108ee9",
                  "100%": "red",
                }}
                size={[400, 10]}
              />
              <p>
                <strong>
                  Llama 3.2 Cost: <span style={{ fontWeight: "400" }}>$0 USD</span>
                </strong>
              </p>
              <InputNumber min={0} value={BillingData.llama} placeholder="Enter amount" name="llama" addonAfter="$" onChange={(value) => HandleChange("llama", value)} />
              <Button type="primary" className="mx-3" onClick={() => showSubmitModal("llama")}>
                Submit
              </Button>
              <Select defaultValue={currentYearHugging} onChange={handleYearChangeHugging} style={{ width: 120, marginLeft: "16px" }}>
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>
              {error.llama && <p style={{ color: "red" }}>{error.llama}</p>}
              <div className="chart">
                <Line data={stabilityDiffusionData} options={options} />
              </div>
            </div>
          </TabPane>





          <TabPane tab="Billing History" key="8">
            <div className="gemini-ai-tab-content">
              <div className="info-section">
                <RangePicker onChange={handleDateChange} format="YYYY-MM-DD" />
              </div>
              <div className="my-3">
                <Button disabled={!BillingCostLLMData || BillingCostLLMData === ""} onClick={handleDownloadCSV}>
                  Download CSV
                </Button>
              </div>
              <div>
                <Table columns={columnsforfilter} dataSource={BillingCostLLMData} pagination={false} style={{ textAlign: "center" }} scroll={{ x: "max-content" }} />
              </div>
            </div>
          </TabPane>
          <TabPane tab="User Report" key="9">
            <div className="gemini-ai-tab-content">
              {/* <div className="info-section">
                <RangePicker format="YYYY-MM-DD" />
              </div> */}
              <Select defaultValue={currentYearUserReport} onChange={handleYearChangeUserReport} style={{ width: 120 }}>
                {years.map((year) => (
                  <Option key={year} value={year}>
                    {year}
                  </Option>
                ))}
              </Select>

              <Select defaultValue={currentMonthDataReport} onChange={handleMonthChangeUserReport} style={{ width: 120, marginLeft: "16px" }}>
                {monthNamesData.map((month) => (
                  <Option key={month} value={month}>
                    {month}
                  </Option>
                ))}
              </Select>

              <div className="my-3">
                <Button onClick={() => handleDownloadCSV1()}>Download CSV Report</Button>
              </div>

              <div>
                <Table
                  columns={columnsforfilterUserReport}
                  dataSource={UsageLLMUserData}
                  pagination={{
                    ...pagination,
                    total: UsageLLMUserData?.length,
                    pageSizeOptions: ["5", "10", "20", "50"],
                    showSizeChanger: true,
                    defaultPageSize: 5,
                  }}
                  onChange={handleTableChange}
                  className="left-align-table"
                  scroll={{ x: "max-content" }}
                />
              </div>
            </div>
          </TabPane>
        </Tabs>

        <Modal
          title="Confirm Submission"
          visible={isSubmitModalVisible}
          onOk={handleConfirmSubmit}
          onCancel={() => setIsSubmitModalVisible(false)}
          okText="Confirm"
          cancelText="Cancel"
        >
          <p>Are you sure you want to submit this data?</p>
        </Modal>
      </div>
    </>
  );
};

export default BillingStatusAdmin;
